import css from '@styled-system/css';
import PlayButtonIcon from 'components/icons/PlayButtonIcon';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import React, { useEffect, useRef, useState } from 'react';
import YouTubePlayer from 'react-player/lib/players/YouTube';
import { useSpring } from 'react-spring';
import { withTheme } from 'styled-components';
import { Box, Absolute } from 'system';
import * as A from 'system/animated';
import smoothScrollTo from 'utils/scroll/smoothScrollTo';
import Img from './Img/index';

function Video({
  theme,
  id,
  video,
  autoPlay = false,
  overlayImage,
  loop,
  useVideo,
  isHeroVideo = false,
  debug = false,
  overlayImageProps = {},
}) {
  const ref = useRef();
  const [inView] = useIntersectionObserver(
    ref,
    {
      rootMargin: `-25% 0% -25% 0%`,
      triggerOnce: false,
    },
    true
  );
  // use the width / height of the youtube video to get the aspect and make the container responsive
  const aspectPercent = video.height / video.width;

  // use the player ready event to determine when to enable the play button
  const [ready, setReady] = useState(false);

  // playing state
  const [playing, setPlaying] = useState(autoPlay === true);
  const [played, setPlayed] = useState(autoPlay === true);

  // global current video playing (passed down from TemplateWrapper)
  const { currentVideo, setCurrentVideo } = useVideo(state => ({
    currentVideo: state.currentVideo,
    setCurrentVideo: state.actions.set,
  }));

  // listen for a change so we can stop the video when another video
  // that isn't this one starts playing
  useEffect(() => {
    if (currentVideo !== id) {
      setPlaying(false);
    }
  }, [currentVideo, id]);

  // set the global current video to this one's id when it's played
  useEffect(() => {
    if (playing) {
      smoothScrollTo(id, null, -50);
      setCurrentVideo(id);
    }
  }, [playing, id, setCurrentVideo]);

  useEffect(() => {
    if (!inView) {
      setPlaying(false);
    }
  }, [inView]);

  // const playButtonProps = useSpring({
  //   opacity: playing ? 0 : 1,
  //   from: { opacity: 1 },
  // });

  const overlayProps = useSpring({
    delay: 300,
    opacity: played ? 0 : 1,
    from: { opacity: 1 },
  });

  const onVideoEnded = () => {};
  return (
    <Box
      ref={ref}
      id={id}
      as={`article`}
      width={1}
      height={
        isHeroVideo
          ? typeof window === `undefined`
            ? video.height
            : Math.round(
                Math.min(
                  aspectPercent * window.innerWidth,
                  window.innerHeight - 100
                )
              )
          : 0
      }
      pt={isHeroVideo ? 0 : `${aspectPercent * 100}%`}
      css={css({
        '& figure': {
          position: `absolute !important`,
          display: `block`,
          width: `100%`,
          height: `100%`,
          top: 0,
          left: 0,
          overflow: `hidden`,
        },
        '& iframe': {
          position: `absolute`,
          top: isHeroVideo ? -60 : 0,
          left: 0,
          width: `100%`,
          height: isHeroVideo ? `calc(100% + 120px)` : `100%`,
          visibility: `visible !important`,
          zIndex: 0,
          border: `none`,
          pointerEvents: `none`,
        },
      })}
    >
      {!isHeroVideo && (
        <Absolute
          as={`figure`}
          bg={theme.colors.grey}
          top={0}
          left={0}
          width={1}
          height={`100%`}
        />
      )}
      {played && (
        <YouTubePlayer
          url={video.url}
          playing={playing}
          width={`100%`}
          height={`100%`}
          wrapper={`figure`}
          config={{
            youtube: {
              playerVars: {
                showinfo: 0,
                modestbranding: 1,
                rel: 0,
                showsearch: 0,
                controls: 0,
              },
            },
          }}
          onReady={() => {
            if (ready) {
              return;
            }
            setReady(true);
          }}
          onPause={() => {
            setPlaying(false);
          }}
          onEnded={onVideoEnded}
          loop={loop}
        />
      )}
      {overlayImage && (
        <A.Absolute
          as={`figure`}
          top={0}
          left={0}
          width={1}
          height={`100%`}
          style={{
            opacity: overlayProps.opacity,
            display: overlayProps.opacity.to(o => (o === 0 ? `none` : `block`)),
          }}
          {...overlayImageProps}
          onLoad={() => {
            if (ready) {
              return;
            }
            setReady(true);
            if (overlayImageProps.onLoad) {
              overlayImageProps.onLoad();
            }
          }}
        >
          <Img {...overlayImage} style={{ width: `100%`, height: `100%` }} />
        </A.Absolute>
      )}
      <A.Button
        borderRadius={0}
        width={1}
        height={`100%`}
        bg={`transparent`}
        display={`flex`}
        alignItems={`center`}
        justifyContent={`center`}
        fontSize={[50, 60, 70, 80, 90]}
        style={{
          position: `absolute`,
          top: 0,
          left: 0,
          cursor: ready ? `pointer` : `default`,
          // opacity: playButtonProps.opacity,
          // display: playButtonProps.opacity.to(o => (o === 0 ? `none` : `flex`)),
        }}
        onClick={() => {
          if (!ready) {
            return;
          }
          setPlayed(true);
          setPlaying(!playing);
        }}
        aria-label="Play video"
      >
        <PlayButtonIcon disabled={ready !== true} hidden={playing === true} />
      </A.Button>
    </Box>
  );
}

export default withTheme(Video);
