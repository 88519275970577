import React from 'react';
import { Button } from 'system';

function ArrowButton({ title, click, direction = `left`, ...props }) {
  return (
    <Button onClick={click} bg={`transparent`} fontSize={[30, 40, 50]}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 30.902 19.625"
        style={{ transform: direction === `right` && `scaleX(-1)` }}
        {...props}
      >
        <title>{title}</title>
        <g fill="none" stroke="#001e45" strokeMiterlimit={10} strokeWidth={2}>
          <path d="M10.52 18.921L1.415 9.816 10.52.711" fillRule="evenodd" />
          <path d="M30.902 9.813H1.414" />
        </g>
      </svg>
    </Button>
  );
}

export default ArrowButton;
