import React, { useEffect } from 'react';
import Img from 'components/Img';
import { H2, H4 } from 'components/typography/headings';
import {
  ParallaxChild,
  ParallaxContainer,
  withParallaxProvider,
} from 'hooks/useParallax';
import { animated } from 'react-spring';
import * as A from 'system/animated';
import { withTheme } from 'styled-components';
import { Box } from 'system';
import { Column, Container, Row } from 'system/layout';
import stripP from 'utils/stripP';

const CUT_HEIGHT = [50, 60, 75, 80, 100];

function BG({ theme, scrollTop }) {
  const pts = scrollTop.to(st => {
    const t = Math.abs(Math.round(st * 0.1));
    const pos = Math.min(t, 100);
    return `0,0 100,0 0,${pos}`;
  });

  return (
    <A.Absolute zIndex={0} top={`100%`} left={0} width={1} height={CUT_HEIGHT}>
      <Box
        as={`svg`}
        width={1}
        height={`100%`}
        viewBox={`0 0 100 100`}
        preserveAspectRatio={`none`}
      >
        <animated.polygon fill={theme.colors.blue} points={pts} />
      </Box>
    </A.Absolute>
  );
}

function CaseStudyHeroBlock({
  theme,
  useVideo,
  title,
  client,
  heroParagraphNode,
  tall = true,
  ref,
  parallax,
  setPageReady,
  setLogoColor,
}) {
  const clientLogo = client.logoLight || client.logo;
  useEffect(() => {
    setLogoColor(theme.colors.white);
    setPageReady(true);
  }, [setLogoColor, setPageReady, theme.colors.white]);

  return (
    <>
      <ParallaxContainer
        normal={[0.25, 0.25, 0.15]}
        pt={[125, 150]}
        pb={tall ? theme.sectionPadding.y : [50, 75, 150, 150, 200]}
        mb={!tall && 50}
        bg={theme.colors.blue}
      >
        <Container>
          <Row pb={[50, 50, 75]}>
            <ParallaxChild
              as={Column}
              offsetY={0.05}
              width={[1 / 2, 1 / 2, 1 / 6]}
              maxWidth={175}
              ml={[0, 0, `${(1 / 12) * 100}%`]}
            >
              <Img {...clientLogo} />
            </ParallaxChild>
          </Row>
          <Row>
            <ParallaxChild
              as={Column}
              offsetY={0.1}
              width={[1, 1, 1, 1 / 2]}
              ml={[0, 0, 0, `${(1 / 12) * 100}%`]}
            >
              <H2
                as={'h1'}
                pr={20}
                color={theme.colors.yellow}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </ParallaxChild>
            <ParallaxChild
              as={Column}
              offsetY={0.15}
              width={[3 / 4, 3 / 4, 3 / 4, 5 / 12]}
              ml={[
                `${(1 / 4) * 100}%`,
                `${(1 / 4) * 100}%`,
                `${(1 / 4) * 100}%`,
                0,
              ]}
              my={[40, 40, 40, 0]}
              style={{ color: theme.colors.white }}
            >
              <H4
                as={`p`}
                style={{ color: theme.colors.white }}
                dangerouslySetInnerHTML={{
                  __html: stripP(heroParagraphNode.childMarkdownRemark.html),
                }}
              />
            </ParallaxChild>
          </Row>
        </Container>
        {!tall && <BG theme={theme} scrollTop={parallax.scrollTop} />}
      </ParallaxContainer>
    </>
  );
}

export default withTheme(withParallaxProvider(CaseStudyHeroBlock));
