import VisuallyHidden from '@reach/visually-hidden';
import Img from 'components/Img';
import { useWindowResize } from 'hooks/index';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as A from 'system/animated';
import { useSpring, useSprings, animated } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import { withTheme } from 'styled-components';
import { Box } from 'system';
import { Column, Container, Row, Section } from 'system/layout';
import ArrowButton from 'components/icons/ArrowButton';
import { withParallaxProvider } from 'hooks/useParallax';

const CUT_HEIGHT = [150, 175, 200, 225, 250];

function BG({ theme, scrollTop }) {
  const pts = scrollTop.to(st => {
    const t = Math.round(st * 0.1);
    const pos = t < 0 ? 0 : Math.abs(t, 0);
    return `0,0 100,0 100,${100 - pos}`;
  });

  return (
    <A.Absolute
      zIndex={0}
      top={[0]}
      left={0}
      width={1}
      height={`80%`}
      display={`flex`}
      flexDirection={`column`}
    >
      <Box width={1} flex={1} bg={theme.colors.blue} />
      <Box width={1} height={CUT_HEIGHT}>
        <Box
          as={`svg`}
          width={1}
          height={`100%`}
          viewBox={`0 0 100 100`}
          preserveAspectRatio={`none`}
        >
          <animated.polygon fill={theme.colors.blue} points={pts} />
        </Box>
      </Box>
    </A.Absolute>
  );
}

function CaseStudyCarouselBlock({ theme, showBg, items, ref, parallax }) {
  const itemRef = useRef();
  const itemWidth = useRef();
  const [current, setCurrent] = useState(0);
  const [currentX, setCurrentX] = useState(0);

  const scrollSpring = useSpring({
    transform: `translate3d(-${currentX}px, 0,0)`,
    config: { mass: 1, tension: 200, friction: 30, clamp: true },
  });

  const springs = useSprings(
    items.length,
    items.map((item, idx) => ({
      opacity: idx === current ? 1 : idx > current ? 0.7 : 0,
      transform: `translate3d(${idx < current ? -50 : 0}px, 0, 0)`,
      config:
        idx < current
          ? { mass: 1, tension: 210, friction: 20, clamp: true }
          : { mass: 1, tension: 170, friction: 26, clamp: true },
    }))
  );

  useEffect(() => {
    const x = itemRef.current.getBoundingClientRect().width * current;
    setCurrentX(x);
  }, [current]);

  useEffect(() => {
    itemWidth.current = itemRef.current.getBoundingClientRect().width;
  }, [itemRef]);

  useWindowResize(() => {
    itemWidth.current = itemRef.current.getBoundingClientRect().width;
  });

  const next = useCallback(
    () => setCurrent(Math.min(current + 1, items.length - 1)),
    [current, items.length]
  );
  const prev = useCallback(() => setCurrent(Math.max(current - 1, 0)), [
    current,
  ]);

  const bind = useGesture(({ down, delta: [xDelta], distance, cancel }) => {
    // prevent massive drag
    if (down && distance > window.innerWidth) cancel();

    // calculate x based on mouse movement
    let x = Math.max(
      0,
      Math.min(currentX - xDelta * 0.1, -(items.length - 2 * itemWidth.current))
    );

    // calculate if the current slide should change
    const targetCurrent = Math.round(x / itemWidth.current);
    if (down) {
      setCurrentX(x);
      setCurrent(targetCurrent);
    } else {
      // when released, snap back to the current slide if in between
      // setCurrent(targetCurrent) will do nothing if we're in between slides, but
      // not far enough either way to change slides
      if (current === targetCurrent) {
        setCurrentX(current * itemWidth.current);
      } else {
        // if current slide should change based on new position, change it
        setCurrent(targetCurrent);
      }
    }
  });

  return (
    <Section ref={ref}>
      {showBg && <BG theme={theme} scrollTop={parallax.scrollTop} />}
      <Container style={{ display: `flex` }} flexDirection={`column`}>
        <Box order={2} mt={10}>
          <ArrowButton
            title="Previous"
            click={prev}
            disabled={current === 0}
            aria-label="Previous"
          />
          <ArrowButton
            title="Next"
            click={next}
            direction="right"
            disabled={current === items.length - 1}
            aria-label="Next"
          />
        </Box>
        <Row
          order={1}
          multiline={false}
          style={{ display: `flex`, ...scrollSpring }}
          css={`
            :hover {
              cursor: grab;
            }
            :active {
              cursor: grabbing;
            }
          `}
          {...bind()}
        >
          <Box as={`ul`} width={1} m={0} style={{ display: `flex` }}>
            {items.map((item, idx) => (
              <Column
                as={`li`}
                tabIndex={-1}
                ref={idx === 0 ? itemRef : null}
                key={item.id}
                width={[1, 1, 5 / 6]}
                style={{ display: `inline-block`, ...springs[idx] }}
              >
                <Img
                  {...item.image}
                  style={{
                    width: `100%`,
                    height: `100%`,
                    userSelect: `none`,
                    userDrag: `none`,
                  }}
                  imgStyle={{
                    userSelect: `none`,
                    userDrag: `none`,
                    WebkitUserDrag: `none`,
                  }}
                />
              </Column>
            ))}
          </Box>
        </Row>
        <VisuallyHidden aria-live={`polite`} aria-atomic={true}>
          {`Item ${current + 1} of ${items.length}`}
        </VisuallyHidden>
      </Container>
    </Section>
  );
}

export default withTheme(withParallaxProvider(CaseStudyCarouselBlock));
