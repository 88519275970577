import React, { useCallback, useEffect, useRef } from 'react';

import CaseStudyCarouselBlock from 'blocks/CaseStudyCarouselBlock';
import CaseStudyContentBlock from 'blocks/CaseStudyContentBlock';
import CaseStudyHeroBlock from 'blocks/CaseStudyHeroBlock';
import CaseStudyImageWithOverlappingTextBlock from 'blocks/CaseStudyImageWithOverlappingTextBlock';
import CaseStudySingleParagraphBlock from 'blocks/CaseStudySingleParagraphBlock';
import CaseStudyTextWithImageBlock from 'blocks/CaseStudyTextWithImageBlock';
import CaseStudyThreeImagesBlock from 'blocks/CaseStudyThreeImagesBlock';
import CaseStudyTwoColumnTextBlock from 'blocks/CaseStudyTwoColumnTextBlock';
import CaseStudyTwoImageWithCaptionsBlock from 'blocks/CaseStudyTwoImageWithCaptionsBlock';
import CaseStudyVideoBlock from 'blocks/CaseStudyVideoBlock';
import { Flex } from 'system';
import Footer from 'components/Footer';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import PlusLink from 'components/PlusLink';
import constants from 'utils/constants';
import { graphql } from 'gatsby';
import theme from 'theme';
import useBreakpoints from 'hooks/useBreakpoints';
import { withGlobals } from 'globals';
import { withTheme } from 'styled-components';

const LOGO_CUT_HEIGHT = {
  tall: [675, 500, 850],
  short: [675, 500, 850],
};

function getIsTall(apiKey) {
  switch (apiKey) {
    case 'video_block':
    case 'carousel_block':
    case 'three_image_block':
      return true;
    default:
      return false;
  }
}

function renderBlock(block, idx, useVideo) {
  if (!block || !block.model) {
    return null;
  }
  const { apiKey } = block.model;
  let BlockComponent = null;
  let extras = {};
  switch (apiKey) {
    case 'carousel_block':
      BlockComponent = CaseStudyCarouselBlock;
      extras = { showBg: idx === 0 };
      break;
    case 'video_block':
      BlockComponent = CaseStudyVideoBlock;
      extras.showBg = idx === 0;
      extras.useVideo = useVideo;
      break;
    case 'text_with_image_block':
      BlockComponent = CaseStudyTextWithImageBlock;
      extras = { showBg: idx === 0 };
      break;
    case 'three_image_block':
      BlockComponent = CaseStudyThreeImagesBlock;
      break;
    case 'two_image_with_captions_block':
      BlockComponent = CaseStudyTwoImageWithCaptionsBlock;
      break;
    case 'two_column_text_block':
      BlockComponent = CaseStudyTwoColumnTextBlock;
      break;
    case 'single_paragraph_block':
      BlockComponent = CaseStudySingleParagraphBlock;
      break;
    case 'image_with_overlapping_text_block':
      BlockComponent = CaseStudyImageWithOverlappingTextBlock;
      break;
    case 'content_block':
      BlockComponent = CaseStudyContentBlock;
      break;
    default:
      break;
  }
  if (!BlockComponent) {
    return null;
  }
  return <BlockComponent key={block.id} {...block} {...extras} />;
}

function CaseStudyPage({
  theme,
  data,
  useVideo,
  footerData,
  setPageReady,
  setLogoColor,
  useNavOpen,
  locale,
}) {
  const navOpen = useNavOpen((state) => state.navOpen);
  const isNavOpen = useRef(navOpen);
  const { title, client, heroParagraphNode } = data.page;
  const heroProps = { title, client, heroParagraphNode };
  const isTall =
    data.page.blocks && data.page.blocks[0]
      ? getIsTall(data.page.blocks[0].model.apiKey)
      : false;

  // check logo on an interval
  let logoInterval = useRef(0);
  const [, bp] = useBreakpoints();

  const checkLogo = useCallback(() => {
    const checkArr = LOGO_CUT_HEIGHT[`${isTall ? `tall` : `short`}`];
    const threshold = checkArr[bp] || checkArr[checkArr.length - 1];
    if (isNavOpen.current || window.pageYOffset > threshold) {
      setLogoColor(theme.colors.blue);
    } else {
      setLogoColor(theme.colors.white);
    }
  }, [
    theme.colors.blue,
    setLogoColor,
    isNavOpen,
    bp,
    isTall,
    theme.colors.white,
  ]);

  useEffect(() => {
    checkLogo();
  }, [bp, checkLogo]);

  useEffect(() => {
    isNavOpen.current = navOpen;
  }, [navOpen]);

  useEffect(() => {
    logoInterval.current = setInterval(
      checkLogo,
      constants.LOGO_CHECK_INTERVAL
    );
    return () => {
      clearInterval(logoInterval.current);
    };
  }, [checkLogo]);

  return (
    <>
      <HelmetDatoCms seo={data.page.seoMetaTags} />
      <CaseStudyHeroBlock
        {...heroProps}
        useVideo={useVideo}
        tall={isTall}
        setPageReady={setPageReady}
        setLogoColor={setLogoColor}
        navOpen={isNavOpen.current}
      />
      {data.page.blocks.map((block, idx) => renderBlock(block, idx, useVideo))}
      <Flex my={[40, 55, 75, 90, 100]} width={1} justifyContent={`center`}>
        <PlusLink
          label={
            locale === `fr` ? `Plus d’études de cas` : `See More Case Studies`
          }
          link={{ title: `Work`, url: locale === `fr` ? `/fr/work` : `/work` }}
        />
      </Flex>
      <Footer data={footerData} showBg={true} />
    </>
  );
}

CaseStudyPage.bgColor = theme.colors.white;

export default withGlobals(withTheme(CaseStudyPage));

export const query = graphql`
  query($uid: String!, $locale: String!) {
    page: datoCmsCaseStudy(id: { eq: $uid }, locale: { eq: $locale }) {
      id
      originalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      client {
        name
        logo {
          url
          alt
          width
          height
          fluid(
            maxWidth: 180
            imgixParams: { auto: "format,compress", q: 100 }
          ) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
        logoLight {
          url
          alt
          width
          height
          fluid(
            maxWidth: 180
            imgixParams: { auto: "format,compress", q: 100 }
          ) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }

      heroParagraphNode {
        childMarkdownRemark {
          html
        }
      }
      blocks {
        ... on DatoCmsCarouselBlock {
          id
          model {
            apiKey
          }
          items {
            id
            image {
              url
              alt
              width
              height
              fluid(
                maxWidth: 1115
                imgixParams: { auto: "format,compress", q: 90 }
              ) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            }
            captionNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on DatoCmsVideoBlock {
          id
          model {
            apiKey
          }
          title
          video {
            title
            provider
            url
            thumbnailUrl
            width
            height
          }
          overlayImage {
            url
            alt
            fluid(
              maxWidth: 1366
              imgixParams: { auto: "format,compress", q: 90 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          captionNode {
            childMarkdownRemark {
              html
            }
          }
          loop
        }
        ... on DatoCmsTextWithImageBlock {
          id
          model {
            apiKey
          }
          layout
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            url
            alt
            width
            height
            fluid(
              maxWidth: 600
              imgixParams: { auto: "format,compress", q: 90 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        ... on DatoCmsThreeImageBlock {
          id
          model {
            apiKey
          }
          images {
            url
            alt
            width
            height
            fluid(
              maxWidth: 600
              imgixParams: { auto: "format,compress", q: 90 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        ... on DatoCmsTwoImageWithCaptionsBlock {
          id
          model {
            apiKey
          }
          image1 {
            url
            alt
            width
            height
            fluid(
              maxWidth: 600
              imgixParams: { auto: "format,compress", q: 90 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          image1Caption
          image2 {
            url
            alt
            width
            height
            fluid(
              maxWidth: 600
              imgixParams: { auto: "format,compress", q: 90 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          image2Caption
        }
        ... on DatoCmsTwoColumnTextBlock {
          id
          model {
            apiKey
          }
          heading
          paragraph1Node {
            childMarkdownRemark {
              html
            }
          }
          paragraph2Node {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsSingleParagraphBlock {
          id
          model {
            apiKey
          }
          alignment
          capitalized
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsImageWithOverlappingTextBlock {
          id
          model {
            apiKey
          }
          image {
            url
            alt
            fluid(
              maxWidth: 900
              imgixParams: { auto: "format,compress", q: 90 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          layout
          capitalize
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsContentBlock {
          id
          model {
            apiKey
          }
          contentNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
