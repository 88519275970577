import { ParallaxChild, ParallaxContainer } from 'hooks/useParallax';
import React from 'react';
import { withTheme } from 'styled-components';
import { Column, Container, Row, Section } from 'system/layout';
import { Box } from 'system';
import Img from 'components/Img/index';

function CaseStudyThreeImagesBlock({ theme, images }) {
  return (
    <Section>
      <Container>
        <ParallaxContainer as={Row} normal={[0.25, 0.25, 0.15]}>
          <ParallaxChild
            as={Column}
            width={[3 / 4, 3 / 4, 1 / 3]}
            ml={[
              `${(1 / 4) * (1 / 2) * 100}%`,
              `${(1 / 4) * (1 / 2) * 100}%`,
              0,
            ]}
            mb={[20, 20, 0]}
            offsetY={0.045}
          >
            <Box width={1}>
              <Img {...images[0]} />
            </Box>
          </ParallaxChild>
          <ParallaxChild
            as={Column}
            width={[3 / 4, 3 / 4, 1 / 3]}
            ml={[
              `${(1 / 4) * (1 / 2) * 100}%`,
              `${(1 / 4) * (1 / 2) * 100}%`,
              0,
            ]}
            mb={[20, 20, 0]}
            offsetY={0.025}
          >
            <Box width={1}>
              <Img {...images[1]} />
            </Box>
          </ParallaxChild>
          <ParallaxChild
            as={Column}
            width={[3 / 4, 3 / 4, 1 / 3]}
            ml={[
              `${(1 / 4) * (1 / 2) * 100}%`,
              `${(1 / 4) * (1 / 2) * 100}%`,
              0,
            ]}
            mb={[20, 20, 0]}
            offsetY={0.055}
          >
            <Box width={1}>
              <Img {...images[2]} />
            </Box>
          </ParallaxChild>
        </ParallaxContainer>
      </Container>
    </Section>
  );
}

export default withTheme(CaseStudyThreeImagesBlock);
