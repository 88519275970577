import { H3 } from 'components/typography/headings';
import { ParallaxChild, ParallaxContainer } from 'hooks/useParallax';
import React from 'react';
import { withTheme } from 'styled-components';
import { Text } from 'system';
import { Column, Container, Row } from 'system/layout';

function CaseTwoColumnTextBlock({
  theme,
  heading,
  paragraph1Node,
  paragraph2Node,
}) {
  return (
    <ParallaxContainer>
      <Container>
        <Row>
          <ParallaxChild
            as={Column}
            offsetY={0.025}
            width={[1, 1, 10 / 12]}
            ml={[0, 0, `${(1 / 12) * 100}%`]}
          >
            <H3>{heading}</H3>
          </ParallaxChild>
        </Row>
        <Row>
          <ParallaxChild
            as={Column}
            width={[1, 1, 5 / 12]}
            ml={[0, 0, `${(1 / 12) * 100}%`]}
            offsetY={0.055}
          >
            <Text
              dangerouslySetInnerHTML={{
                __html: paragraph1Node.childMarkdownRemark.html,
              }}
              fontSize={[14, 16, 18]}
            />
          </ParallaxChild>
          <ParallaxChild as={Column} width={[1, 1, 5 / 12]} offsetY={0.065}>
            <Text
              dangerouslySetInnerHTML={{
                __html: paragraph2Node.childMarkdownRemark.html,
              }}
              fontSize={[14, 16, 18]}
            />
          </ParallaxChild>
        </Row>
      </Container>
    </ParallaxContainer>
  );
}

export default withTheme(CaseTwoColumnTextBlock);
