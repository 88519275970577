import { H3 } from 'components/typography/headings';
import { ParallaxChild, ParallaxContainer } from 'hooks/useParallax';
import React from 'react';
import { withTheme } from 'styled-components';
import { Column, Container, Row, Section } from 'system/layout';
import Img from 'components/Img/index';

function CaseStudyImageWithOverlappingTextBlock({
  theme,
  layout,
  paragraphNode,
  image,
  capitalize,
}) {
  const isRTL = layout === `Image left - content right`;
  return (
    <Section>
      <Container>
        <ParallaxContainer
          as={Row}
          multiline={[true, true, false]}
          flexDirection={isRTL ? `row` : `row-reverse`}
        >
          <ParallaxChild
            as={Column}
            width={[1, 1, 8 / 12]}
            offsetY={0.025}
            order={isRTL ? [2, 2, 1] : 1}
          >
            <Img {...image} />
          </ParallaxChild>
          <ParallaxChild
            as={Column}
            width={[1, 1, 5 / 12]}
            style={{ display: `flex` }}
            alignItems={`center`}
            mr={[0, 0, isRTL || `${-(1 / 12) * 100}%`]}
            ml={[0, 0, isRTL && `${-(1 / 12) * 100}%`]}
            mb={isRTL && [`-5%`, `-5%`, 0]}
            mt={!isRTL && [`-5%`, `-5%`, 0]}
            offsetY={0.01}
            order={isRTL ? [1, 1, 2] : 2}
          >
            <H3
              dangerouslySetInnerHTML={{
                __html: paragraphNode.childMarkdownRemark.html,
              }}
            />
          </ParallaxChild>
        </ParallaxContainer>
      </Container>
    </Section>
  );
}

export default withTheme(CaseStudyImageWithOverlappingTextBlock);
