import React from 'react';
import { Box } from 'system/animated';

const SvgComponent = ({
  svgRef,
  title,
  disabled,
  hidden = false,
  style,
  ...props
}) => (
  <Box
    as={`svg`}
    width="1em"
    height="1em"
    viewBox="0 0 90 90"
    ref={svgRef}
    style={{
      ...style,
      willChange: `opacity`,
      opacity: hidden ? 0 : disabled ? 0.5 : 1,
      transition: `opacity 0.2s ease-out`,
    }}
    {...props}
  >
    <title>{title}</title>
    <g transform="translate(-6576 4322)">
      <circle
        cx={45}
        cy={45}
        r={45}
        transform="translate(6576 -4322)"
        fill="#001e45"
      />
      <path d="M6636.187-4276.8l-23.236-14.541v28.602z" fill="#f5eb0a" />
    </g>
  </Box>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <SvgComponent svgRef={ref} {...props} />
));
export default ForwardRef;
