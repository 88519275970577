import { H3 } from 'components/typography/headings';
import { ParallaxChild, ParallaxContainer } from 'hooks/useParallax';
import React from 'react';
import { withTheme } from 'styled-components';
import { Column, Container, Row, Section } from 'system/layout';

function CaseStudySingleParagraphBlock({
  theme,
  alignment,
  capitalized,
  paragraphNode,
}) {
  return (
    <Section>
      <Container>
        <ParallaxContainer as={Row}>
          <ParallaxChild
            as={Column}
            width={[1, 1, 2 / 3]}
            ml={[0, 0, alignment === `right` ? `${(1 / 3) * 100}%` : `auto`]}
            mr={[0, 0, alignment === `left` ? `${(1 / 3) * 100}%` : `auto`]}
            offsetY={0.055}
          >
            <H3
              dangerouslySetInnerHTML={{
                __html: paragraphNode.childMarkdownRemark.html,
              }}
              width={1}
              style={{
                textAlign: `${alignment}`,
                textTransform: `${capitalized && 'uppercase'}`,
              }}
            />
          </ParallaxChild>
        </ParallaxContainer>
      </Container>
    </Section>
  );
}

export default withTheme(CaseStudySingleParagraphBlock);
