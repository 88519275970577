import { ParallaxChild, ParallaxContainer } from 'hooks/useParallax';
import React from 'react';
import { withTheme } from 'styled-components';
import { Text } from 'system';
import { Column, Container, Row, Section } from 'system/layout';
import Img from 'components/Img/index';

function CaseStudyTwoImageWithCaptionsBlock({
  theme,
  align = 'ltr',
  image1,
  image1Caption,
  image2,
  image2Caption,
}) {
  return (
    <Section>
      <Container>
        <ParallaxContainer as={Row}>
          <ParallaxChild
            as={Column}
            width={[1, 1, 5 / 12]}
            ml={[0, 0, align === `rtl` && `${(1 / 6) * 100}%`]}
            mb={[40, 40, 0]}
            offsetY={0.035}
          >
            <Img {...image1} />
            <Text
              width={1}
              mt={[15, 17, 20]}
              textAlign={align === `rtl` && `right`}
              dangerouslySetInnerHTML={{ __html: image1Caption }}
              fontSize={[14, 16, 18]}
            />
          </ParallaxChild>
          <ParallaxChild
            as={Column}
            width={[1, 1, 5 / 12]}
            ml={[0, 0, align === `rtl` && `${(1 / 6) * 100}%`]}
            offsetY={0.055}
          >
            <Img {...image2} />
            <Text
              width={1}
              mt={20}
              textAlign={align === `rtl` && `right`}
              dangerouslySetInnerHTML={{ __html: image2Caption }}
              fontSize={[14, 16, 18]}
            />
          </ParallaxChild>
        </ParallaxContainer>
      </Container>
    </Section>
  );
}

export default withTheme(CaseStudyTwoImageWithCaptionsBlock);
