import Content from 'components/Content';
import { H3 } from 'components/typography/headings';
import Video from 'components/Video';
import { ParallaxChild, withParallaxProvider } from 'hooks/useParallax';
import React from 'react';
import { withTheme } from 'styled-components';
import { Section, Column, Container, Row } from 'system/layout';
import validateNode from 'utils/validateNode';
import * as A from 'system/animated';
import { animated } from 'react-spring';
import { Box } from 'system';

const CUT_HEIGHT = [150, 175, 200, 225, 250];

function BG({ theme, scrollTop }) {
  const pts = scrollTop.to(st => {
    const t = Math.round(st * 0.1);
    const pos = t < 0 ? 0 : Math.abs(t, 0);
    return `0,0 100,0 100,${100 - pos}`;
  });

  return (
    <A.Absolute
      zIndex={0}
      top={[0]}
      left={0}
      width={1}
      height={`80%`}
      display={`flex`}
      flexDirection={`column`}
    >
      <Box width={1} flex={1} bg={theme.colors.blue} />
      <Box width={1} height={CUT_HEIGHT}>
        <Box
          as={`svg`}
          width={1}
          height={`100%`}
          viewBox={`0 0 100 100`}
          preserveAspectRatio={`none`}
        >
          <animated.polygon fill={theme.colors.blue} points={pts} />
        </Box>
      </Box>
    </A.Absolute>
  );
}

function CaseStudyVideoBlock({
  id,
  title,
  theme,
  captionNode,
  video,
  overlayImage,
  loop = false,
  showBg,
  useVideo,
  ref,
  parallax,
}) {
  const hasCaption = validateNode(captionNode);
  return (
    <Section ref={ref}>
      {showBg && <BG theme={theme} scrollTop={parallax.scrollTop} />}
      <Container>
        <Row
          width={[`100vw`, `100vw`, 1]}
          ml={[`50%`, `50%`, -3, -4, -5]}
          mr={[0, 0, -3, -4, -5]}
          transform={[
            `translate3d(-50vw,0,0)`,
            `translate3d(-50vw,0,0)`,
            `none`,
          ]}
        >
          {title && (
            <ParallaxChild as={Column} width={1} offsetY={0.1}>
              <H3>{title}</H3>
            </ParallaxChild>
          )}
          <ParallaxChild as={Column} width={1} offsetY={0.15}>
            <Video
              id={`${id}-video`}
              video={video}
              useVideo={useVideo}
              loop={loop}
              overlayImage={overlayImage}
            />
          </ParallaxChild>
          {hasCaption && (
            <ParallaxChild as={Column} width={1} offsetY={0.175}>
              <Content
                dangerouslySetInnerHTML={{
                  __html: captionNode.childMarkdownRemark.html,
                }}
              />
            </ParallaxChild>
          )}
        </Row>
      </Container>
    </Section>
  );
}

export default withTheme(withParallaxProvider(CaseStudyVideoBlock));
