import { ParallaxChild, ParallaxContainer } from 'hooks/useParallax';
import React from 'react';
import { withTheme } from 'styled-components';
import { Column, Container, Row, Section } from 'system/layout';
import Content from 'components/Content';
import Img from 'components/Img/index';

function CaseStudyTextWithImageBlock({ theme, layout, paragraphNode, image }) {
  const isRTL = layout === `Image left - content right`;
  return (
    <Section>
      <Container>
        <ParallaxContainer
          as={Row}
          flexDirection={[`column`, `column`, isRTL ? `row-reverse` : `row`]}
        >
          <ParallaxChild
            as={Column}
            width={[1, 1, 5 / 12]}
            ml={[0, 0, `${(1 / 12) * 100}%`]}
            offsetY={0.01}
          >
            <Content
              dangerouslySetInnerHTML={{
                __html: paragraphNode.childMarkdownRemark.html,
              }}
              fontSize={[14, 16, 18]}
            />
          </ParallaxChild>
          <ParallaxChild
            as={Column}
            width={[1, 1, 5 / 12]}
            ml={[0, 0, `${(1 / 12) * 100}%`]}
            offsetY={0.025}
          >
            <Img {...image} />
          </ParallaxChild>
        </ParallaxContainer>
      </Container>
    </Section>
  );
}

export default withTheme(CaseStudyTextWithImageBlock);
